@import "./settings/variables";
@import "functions";
@import "./mixins/responsive";

.detail {
  &__description {
    max-width: rem(640);
    margin-bottom: rem(24);
    white-space: pre-wrap;
  }

  &__group {
    display: flex;
    width: 100%;
    justify-content: space-between;

    > *:last-child {
      @include IE() {
        margin-left: auto;
      }
    }

    .headline-4 {
        align-self: center;
    }
  }

  &__btns {
    .input-file,
    .button {
      @include respond-max(medium-up) {
        display: block;
        width: 100%;
        margin: 0;
        ~ .button {
          margin: rem(16) 0 0 0 !important;
        }
      }
    }
    &--small {
      .input-file,
      .button {
        @include respond-max(medium-small-up) {
          display: block;
          width: 100%;
          margin: 0;
          ~ .button {
            margin: rem(16) 0 0 0 !important;
          }
        }
      }
    }
  }

  &__form {
    @include respond-max(medium-small-up) {
      .button {
        width: 100%;
        text-align: center;
        ~ .button {
          margin: rem(16) 0 0 0;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  .table {
    &__row:last-child td {
      border-bottom: none;
    }
  }
}