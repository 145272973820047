@import "../../../styles/settings/variables";

.published-standards {
  .documents-table {
    min-height: rem(170);
    .filename {
      overflow: visible;
      white-space: unset;
      text-overflow: unset;
    }
  }

  &__external_link {
    text-align: center;
    font-size: 20px;

    a {
      color: $colorBlue;
      text-decoration: underline;
    }
  }
}
