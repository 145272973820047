@import "settings/variables";

.panel {
  width: calc(100% - 24px * 2);
  margin: $gap;
  background-color: #fff;

  &--border {
    border: 1px solid $colorGrey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    border-radius: 8px;
  }

  &--with-tab.panel {
    position: relative;
    border-top-left-radius: 0;
    width: 100%;
    padding: $gap;
    margin-top: rem(64);
  }
  
  &__group {
    padding: rem(16);

    &--small {
      padding: rem(12) rem(16);
    }
  }

  &__separator {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $colorGrey;
  }
}