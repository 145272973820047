@import "/settings/variables";
@import "/functions";
@import "/mixins/responsive";


.tag {
  display: inline-block;
  padding: rem(3) rem(8);
  font-size: rem(12);
  text-align: center;
  text-transform: uppercase;
  border-radius: rem(4);
  &--new {
    background: rgba(15, 178, 103, 0.7);
  }
}