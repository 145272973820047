@import "settings/variables";

.navigation {
  margin: 0;
  padding: 0 rem(8);
  list-style-type: none;
  overflow-x: auto;
  text-align: center;

  .is-mobile & {
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  &__link {
    display: inline-block;
    // margin: 0 2px;

    a {
      display: block;
      padding: rem(14) rem(16) rem(10);
      color: $colorBrightGrey;
      border-bottom: 4px solid transparent;
    }

    .active {
      color: $colorDark;
      border-color: $colorBlue;
    }
  }

  &--align-left {
    text-align: left;
    padding: 0;
  }

  &--underline {
    position: relative; 
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      width: auto;
      height: rem(1);
      left: rem(-16);
      right: rem(-16);
      bottom: 0;
      background-color: $colorGrey;
    }
  }

  &--inactive {
    pointer-events: none;
    cursor: unset;
    opacity: 0.8;    
    .active {
      border-color: $colorGrey;
    } 
  }
}

