@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/form/group";

.meetings {
  &__sidebar {
    & > .button--link {
      margin: 0;
    }

    .checkbox__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: rem(16) 0;
      margin: rem(16) 0;
      border-top: 1px solid $colorGrey;
      border-bottom: 1px solid $colorGrey;

      input {
        width: rem(20);
        height: rem(20);
        margin: 0 rem(16) 0 rem(2) !important;
      }

      label {
        font-size: rem(14);
        line-height: rem(20);
      }
    }
  }
}

.notification-card {
  &__user-bar {
    display: flex;
    position: relative;
    padding-right: rem(40);

    > span {
      margin-top: rem(4);
      display: block;
    }
    .date {
      color: $colorBrightGrey;
    }

    .avatar {
      // float: left;
      margin-right: rem(8);
    }
  }

  &__bar {
    position: relative;
    padding-right: rem(40);
    padding-bottom: rem(16);
    margin-bottom: rem(16);

    &:after {
      content: "";
      position: absolute;
      height: rem(1);
      left: rem(-16);
      right: rem(-16);
      bottom: 0;
      background-color: $colorGrey;
    }

    span {
      display: block;
    }
    .date {
      margin-top: rem(4);
      color: $colorBrightGrey;
    }
  }

  &__heading {
    padding-top: rem(12);
  }

  &__header {
    padding-bottom: rem(16);
  }

  &__member.member {
    margin: 0 0 rem(8);
    width: 100%;
  }

  &__group {
    @extend .group;
    padding: rem(16) 0 0;

    &--underlined {
      @extend .group--underlined;
      padding: rem(16) 0;

      &:after {
        width: auto;
        left: rem(-16);
        right: rem(-16);
      }
    }

    &--first {
      padding-top: 0;
    }
  }
}
