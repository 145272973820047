@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.filters {
  .filters__button {
    align-self: flex-end;

    @include respond-max(large-up) {
      align-self: stretch;
    }
  }
}