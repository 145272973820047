@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";

.related-votes {
    .user-vote {
        text-transform: uppercase;

        @include respond-max(medium-small-up) {
            display: block;
            width: 100%;
            margin-left: rem(72);
        }

        &__single {
            &--yes      { color: #0eb266; }
            &--no       { color: #ff5454; }
            &--confirm  { color: #0eb266; }
            &--revise   { color: #ffaa54; }
            &--withdraw { color: #ff5454; }
            &--abstain  { color: rgba(127, 144, 158, 1); }
        }
    }
}