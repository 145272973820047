@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.official-vote {
    margin-top: rem(20);

    &__bar {
        background-color: rgb(255, 251, 180);
        text-align: center;
        vertical-align: middle;
        line-height: rem(40);
        border-radius: $radius-small;
    }

    &__label{
        font-weight: 700;
    }

    &__link{
        position: absolute;
        right: rem(15);
        
        @include respond-max(large-up) {
            margin-right: rem(15);
            position: unset;
            display: inline-block;
            float: right;
        }

        @include respond-max(medium-plus) {
            margin: 0;
            padding-bottom: rem(10);
            position: unset;
            display: block;
            float: unset;
            line-height: rem(10);
            vertical-align: middle;
        }
    }

    &__note {
        display: block;
        text-align: center;
        margin-top: rem(5);
        font-size: rem(12);
    }
}