@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.action-box {
  position: relative;

  &__panel {
    position: absolute;
    right: 0;
    z-index: 1;

    &.panel {
      width: auto;
      min-width: rem(180);
      margin: 0;
    }
  }

  &__panel--is-open {
    display: block;
  }
}

.actions-list {
  .list__element {
    padding: 0;
  }

  &__button {
    padding: $gap/2 $gutter2;
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }

  &__disabled {
    color: $colorGrey;
    cursor: default;
  }
}
