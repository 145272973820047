@import "settings/variables";
@import "functions";
@import "./mixins/responsive";

.user-box {
  position: relative;

  &__button {
    // SVG/TEXT color white
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%)
      hue-rotate(314deg) brightness(106%) contrast(101%);

    outline: none;
    background-color: transparent;
    width: rem(45);
    height: rem(45);

    .icon-search {
      width: rem(31);
      height: rem(31);
    }

    .icon-profile-icon {
      width: rem(31);
      height: rem(31);
    }
  }

  &__dropdown {
    position: absolute;
    width: auto;
    min-width: rem(200);
    // right: rem(6);
    // top: rem(40);
    left: rem(-40);
    bottom: rem(50);
    margin: 0;
    background-color: $colorLight;
    white-space: nowrap;
    z-index: 1;

    a,
    .button--link {
      margin: 0;
      padding: 0 rem(24);
      text-align: left;
      color: $colorBlue;
    }
  }
}
