@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";


.deadline-details {
    &__description {
        padding-top: rem(16);
        white-space: pre-wrap;
    }

    &__stepper {
        padding: 0;

        &> p {
            margin: 0 0 rem(16);
        }
    }

    &__votes {
        padding-bottom: rem(40);
    }
}