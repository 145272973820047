@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/form/group";

.references {
  // .input-file--inline {
  //   margin-left: 0.5rem;
  //   margin-right: 0;
  // }

  .meetings {
    display: block;
    tr {
      th,
      td {
        // text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;

        &.checkbox {
          width: rem(35);
        }

        &.committee {
          width: rem(90);
        }

        &.committeeName {
          width: rem(160);
        }

        &.title {
          width: rem(450);
          max-width: rem(450);
        }

        &.FromDate {
          width: rem(120);
        }

        &.ToDate {
          width: rem(120);
        }

        &.location {
          width: rem(150);
          max-width: rem(150);
        }

        &.last {
          width: rem(20);
        }
      }
    }
  }

  .deadlines {
    display: block;
    tr {
      th,
      td {
        // text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;

        &.checkbox {
          width: rem(35);
        }

        &.committee {
          width: rem(90);
        }

        &.committeeName {
          width: rem(160);
        }

        &.title {
          width: rem(650);
        }

        &.date {
          width: rem(130);
        }

        &.last {
          width: rem(20);
        }
      }
    }
  }

  .layout__sidebar {
    min-width: 100%;
  }

  .layout__cards {
    min-width: 100%;
  }
}
