@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.consultant-menu {
  position: fixed;
  right: rem(24);
  bottom: rem(24);
  z-index: 10;

  &__toggler {
    display: block;
    position: relative;
    width: rem(48);
    height: rem(48);
    margin: 0;

    border: 1px solid $colorGrey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    background-color: $colorLight;
    border-radius: 50%;

    transform-origin: center;
    transition: transform 0.3s ease-in-out;

    .opened & {
      transform: rotate(45deg);
    }
  }
  &__icon {
    position: absolute;
    width: rem(20);
    height: rem(2);
    left: rem(13);
    top: rem(23);
    background-color: $colorBlue;

    + .consultant-menu__icon {
      width: rem(2);
      height: rem(20);
      top: rem(14);
      left: rem(22);
    }
  }

  &__content {
    position: absolute;
    right:0;
    bottom: rem(48);
    width: auto;
    margin: 0 0 rem(16);
  }

  &__element {
    padding: 0;
  }

  &__button {
    padding: $gap/2 $gutter2;
    width: 100%;
    min-width: rem(280);
    text-align: left;
    white-space: nowrap;

    @include respond-max(small-up) {
      min-width: rem(240);
    }
  }

  a {
    color: $colorBlue;
  }
}