@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.side-menu {
  width: 100%;
  // TODO: this is for side menu search
  // height: calc(100vh - 215px);
  overflow: auto;

  a {
    display: flex;
    padding: $gap/2 rem(32);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: $colorLight;
  }

  &__committee-div {
    display: flex;
    justify-content: space-between;
    padding: $gap/2 rem(32);
    width: 100%;
    border-bottom: 1px solid #334d64;

    .icon {
      filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(300deg)
        brightness(106%) contrast(101%);
    }
  }

  &__committee {
    color: var(--White, #fff);
    font-size: 16px;
    font-weight: 700;
  }

  &__search {
    display: flex;
    padding: rem(12) rem(32);
    flex-direction: column;

    div {
      width: rem(180);
      height: rem(35);
      display: flex;
      background: inherit;
      border-radius: 32px;
      border: 1px solid var(--DS-NAVY---Pri---80, #334d64);
    }

    input {
      padding-left: 5px;
      background: inherit;
      width: rem(145);
      color: var(--DS-NAVY---Pri---60, #66798b);
      border: 0;
    }

    button {
      padding: 0;
      .icon-sidebar-search {
        padding: 0;
        width: rem(20);
        height: rem(20);
      }
    }
  }

  &__link {
    padding: 0;
    border-color: rgba(51, 77, 100, 1);

    // What is this margin-bottom for?
    // &:nth-child(n + 3):last-child {
    //   margin-bottom: 250%;
    // }
    // &:nth-child(n + 6):last-child {
    //   margin-bottom: 150%;
    // }
    &:nth-child(n + 10):last-child {
      margin-bottom: 0;
    }

    a {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: rem(4);
      }
    }

    .active {
      &::before {
        background-color: $colorBlue;
      }
    }

    :hover {
      background-color: rgba(23, 71, 114, 0.9);
      // transition: background-color 0.3s ease;
    }
  }

  &__title {
    margin-right: auto;
  }

  &__counter {
    display: flex;
    width: $gap;
    min-width: $gap;
    height: $gap;
    margin-left: $gutter;
    justify-content: center;
    align-items: center;
    float: right;
    background-color: $colorGrey;
    color: $colorDark;
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 700;
    border-radius: 50%;
  }
}
