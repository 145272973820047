@import "../../../styles/settings/variables";

.about-docs-table {
  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid #e4e4e4;
    }
  }
  .doc-link {
    width: 50%;
    vertical-align: top;
    border-bottom: none;
  }
  .download-button {
    background: transparent;
    color: $colorBlue;
    cursor: pointer;
  }
  .description {
    display: flex;
    justify-content: space-between;
    border-bottom: none;
    .edit-buttons {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
    }
    .input-wrapper {
      width: 100%;
    }
    p {
      margin: 0;
    }
  }
}
.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.about-page {
  & .about-docs-panel {
    padding: 0.75rem 2rem;
  }
  .editable {
    p {
      max-width: 630px;
    }
  }
  .editable-full-width {
    p {
      width: 100%;
    }
  }
  .general-documents-header {
    display: flex;
    align-items: center;
    button {
      margin-left: 10px;
    }
  }
}
