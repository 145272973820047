@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";
@import "../../../styles/form/group";

.checkbox__group {
  display: flex;
  flex-direction: column;
  gap: rem(10);
}
