.notify-modal {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  transition: top 0.3s ease-in-out;

  &.visible {
    top: 2%;
  }

  &.error {
    .notify-modal__content {
      border-left: 0.5rem solid #e04a4a;
    }
  }

  &.success {
    .notify-modal__content {
      border-left: 0.5rem solid #0eb266;
    }
  }

  &.warning {
    .notify-modal__content {
      border-left: 0.5rem solid #e7dc85;
    }
  }

  &__content {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 10px 25px;
  }

  // &__title {
  //   font-size: 16px;
  //   margin-bottom: 10px;
  // }

  &__message {
    font-size: 14px;
    margin: 0;
  }
}
