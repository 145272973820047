@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.stepper {
    padding: 0;
    height: rem(90);
    width: 100%;
    max-width: rem(1280);

    @include respond-max(large-up) {
        width: rem(754);    
    }

    &__wrapper {
        position: relative;
        overflow-x: auto;
        z-index: 1;

        @include respond-max(large-up) {
            &:after {
                content: ' ';
                width: $gap/2;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            }
        }
    }

    li {
        list-style: none;
        float: left;
        position: relative;
        text-align: left;
        font-size: rem(12);
        font-weight: 700;

        span {
            display: block;
            line-height: rem(12);
            font-weight: 400;
        }
    }
    
    li:after {
        content: '';
        width: rem(8);
        height: rem(8);
        border: 1px solid #d8d8d8;
        display: block;
        text-align: center;
        margin: 0 auto rem(10) 0;
        border-radius: 50%;
        background-color: #d8d8d8;
        margin-top: rem(15);
        position: relative;
    }

    li:before {
        content: '';
        position: absolute;
        width: 100%;
        height: rem(2);
        background-color: #d8d8d8;
        bottom: rem(13);
        left: -100%;
        z-index: -1;
    }

    li:first-child:before {
        content: none;
    }

    .is-active:after {
        background-color: #01203c;
        border: 1px solid #01203c;
    }

    .is-active:before {
        background-color: #01203c;
    }

    .highlight:after {
        height: rem(16);
        width: rem(16);
        margin-top: 11px;
    }

    .highlight:before {
        bottom: rem(17);
    }
}

.codes-stepper {
    display: flex;
    list-style: none;
    gap: 4px;
    padding-left: 0;
    .step {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        padding: 16px;
        transition: flex 0.6s ease;
        border-radius: 4px;
        &__code {
            // font-size: 1rem;
        }
        &__title {
            margin-left: 16px;
        }
        &__hero {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            font-weight: bold;
            color: #333333;
            margin-top: 3px;
        }
        &__expand-btn {
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        &__description {
            padding-top: 12px;
            padding-left: 30px;
            padding-right: 30px;
            color: #333333;
            font-size: 0.75rem;
        }
        &__expand-icon {
            transition: transform 0.2s ease;
            &.open {
                transform: rotate(180deg);
            }
        }
        details {
            height: 100%;
            width: 100%;
            summary {
                display: flex;
                width: 100%;
                list-style: none;
                justify-content: center;
                &::-webkit-details-marker {
                    display: none;
                }
            }
        }
        &.is {
            &-previous {
                background-color: #C4DFF4;
            }
            &-current {
                background-color: #88BFE8;
            }
            &-next {
                background-color: #f2f4f5;
            }
        }
        &.active {
            flex: 1;
            & .step__hero {
                justify-content: flex-start;
                width: 100%;
            }
        }
        &:not(.active) {
            cursor: pointer;
        }
    }
}