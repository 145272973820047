@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.committee-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__committee {
    font-size: rem(14);
    font-weight: 700;
    color: #333333;
    padding-right: rem(10);
  }

  &__title {
    font-size: rem(14);
    font-weight: 500;
    color: #333333;
  }

  // counter as a badge, right top with small blue circle background
  &__counter {
    display: flex;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    justify-content: center;
    align-items: center;
    float: right;
    background-color: $colorGrey;
    color: $colorDark;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    border-radius: 50%;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    &.active {
      background-color: #f5f5f5;
    }

    &__a {
      // list a tag has display block
      display: flex !important;
      width: inherit;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
