@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.dropdown {
  position: relative;

  &__button {
    // SVG/TEXT color white
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%)
      hue-rotate(314deg) brightness(106%) contrast(101%);

    background-color: transparent;
    margin-right: rem(8);
    width: rem(60);
    height: rem(45);
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon {
      transform: rotate(90deg);
      width: rem(20);
      height: rem(20);
      float: right;
    }
  }

  &__selected-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__panel {
    position: absolute;
    width: auto;
    min-width: rem(150);
    // right: rem(6);
    // top: rem(40);
    left: rem(6);
    bottom: rem(50);
    margin: 0;
    background-color: $colorLight;
    white-space: nowrap;
    z-index: 1;

    .button--link {
      margin: 0;
      padding: 0 rem(24);
      text-align: left;
      color: $colorBlue;
    }
  }
}
