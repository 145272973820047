.committee-setting {
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .list-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 0;
      border-bottom: 1px solid #e0e0e0;

      .list-element-name {
        display: flex;
        flex-direction: row;

        .field-description {
          margin: 0 0 0 0.5rem;
        }
      }

      .list-element-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .list-element-button-icon {
          font-size: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
