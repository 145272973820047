@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";

.group {
  padding: $gap 0;

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.group--small {
  padding: rem(16) 0;
}

.group--field {
  padding: rem(8) 0;
}

.group--btn {
  padding: rem(24) 0 0;
}

.group--first {
  padding-top: 0;
}

.group--inline {
  padding: 0;
}

.group--underlined {
  position: relative;
  padding-bottom: rem(24);

  &.group--small {
    padding-bottom: rem(16);
  }

  &:after {
    content: "";
    position: absolute;
    height: rem(1);
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $colorGrey;
  }
}

.group--last-underlined,
.group--full-underlined {
  @extend .group--underlined;
  &:after {
    width: calc(100% + (#{$gap} * 2));
    margin-left: -$gap;
    margin-right: -$gap;
  }
}

.group--row {
  .group__element {
    display: flex;

    .button {
      white-space: nowrap;
    }
  }
}

.group__flex {
  padding: rem(16) 0 0;
  display: flex;
  flex-direction: row;

  @include respond-max(large-up) {
    flex-direction: column;
  }

  .group--first > & {
    padding-top: 0;
  }

  > * {
    margin-right: rem(16);
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    @include respond-max(large-up) {
      margin-bottom: rem(16);
    }

    @include respond-max(medium-up) {
      margin-right: 0;
    }
  }

  &-content {
    width: 100%;
  }

  &-column {
    margin-right: rem(24);

    &:last-child {
      margin-right: 0;
    }
    @include respond-max(large-up) {
      margin-bottom: rem(24);
      margin-right: 0;
    }
  }

  .select {
    width: 0;
    flex: 1 1 100%;

    @include respond-max(large-up) {
      width: 100%;
      flex: 0 1 auto;
    }
  }
}

.group--message {
  padding: rem(20) 0;
}

.group--comments {
  .group__flex {
    align-items: flex-end;
    @include respond-max(large-up) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    > div {
      flex-grow: 1;
      flex-basis: 25%;
      @include respond-max(large-up) {
        flex: 1 0 50%;
        max-width: calc(50% - 16px);
      }
      @include respond-max(medium-up) {
        flex-direction: column;
        flex: 1 1 100%;
        max-width: none;
      }
    }

    .select {
      width: auto;
    }
  }
}

.gray-box {
  padding: 0.6875rem 1rem;
  background-color: $colorBackground;
  border: 0.0625rem solid $colorPaleGreyBlue;
  border-radius: 0.25rem;
  margin: rem(10) 0;
}
