@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.toggler {
  &__content {
    display: none;
  }

  &.opened {
    .toggler__content {
      display: block;
    }
  }
}
