@import "./settings/variables";
@import "./functions";
@import "./mixins/responsive";

.avatar {
    width: rem(92);
    min-width: rem(92);
    height: rem(92);

    &--small {
        width: rem(48);
        min-width: rem(48);
        height: rem(48);

        .avatar__acronym {
            font-size: rem(14);
            line-height: rem(20);
        }
    }

    &__img {
        width: 100%;
        height: auto;
        display: block;
        border-radius: 50%;
    }
    
    &__placeholder {
        display: flex;
        height: 100%;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: $colorMediumBlue;
    }
    
    &__acronym {
        font-weight: 700;
        font-size: rem(32);
        line-height: rem(40);
        color: $colorDarkBlue;
    }
}