@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.documents {
  &__buttons {
    .input-file--inline {
      margin-left: rem(8);
    }
  }

  &__buttons-reset {
    display: block;
    margin: rem(16) auto 0 auto;
    line-height: rem(20);
    color: $colorBlue;
    cursor: pointer;
  }

  @include respond-max(medium-up) {
    &__buttons .button {
      margin-bottom: rem(8);
    }
  }

  @include respond-max(medium-plus) {
    &__buttons {
      .input-file--inline {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .button {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  @include respond-max(large-up) {
    &__buttons {
      text-align: right;
    }
  }
}

.documents-table {
  th {
    font-weight: 400;
  }
  td {
    vertical-align: top;
  }

  &__row {
    &:nth-child(1):last-child:not(.documents-table__header) {
      margin-bottom: $gap;
    }
  }

  &__wrapper {
    overflow-x: visible;
  }

  .id {
    min-width: rem(35);
  }

  .committee {
    position: relative;
    min-width: rem(90);
    font-size: rem(12);
  }

  .name {
    max-width: rem(444);

    @include respond-max(large-up) {
      min-width: rem(320);
    }
  }

  .action {
    min-width: rem(120);
    margin-left: auto;
  }

  // .related-content {

  // }

  .date {
    position: relative;
    min-width: rem(84);
    font-size: rem(12);
    text-align: right;
    word-wrap: break-word;
  }

  .last {
    min-width: rem(46);
    padding-right: 0;
    text-align: right;
  }

  .committee > span,
  .action span,
  .fileinfo span {
    word-wrap: break-word;
  }

  .type {
    color: $colorBrightGrey;
    line-height: rem(20);
  }

  .filename {
    display: block;
    margin-top: $gutter/2;
    color: $colorBlue;
    font-size: rem(14);
    line-height: rem(20);
    // overflow: hidden;
    // white-space: nowrap;
    overflow-wrap: break-word;
    max-width: rem(440);
    margin-right: $gap;

    &--disabled {
      color: $colorDarkGray;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .step {
        &__expand-btn {
          background-color: transparent;
          border: none;
          cursor: pointer;
          align-self: flex-end;
        }
        &__expand-icon {
          transition: transform 0.2s ease;
          min-width: 20px;
          max-width: 20px;
          &.open {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .filedescription {
    color: $colorBrightGrey;
    line-height: rem(20);
    overflow-wrap: break-word;
    max-height: 70px;
    overflow-y: hidden;
    scrollbar-width: thin;
    margin-top: 5px;
    position: relative;
    &--isBig {
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 99%
        );
        width: 100%;
        height: 4em;
      }
    }
    &--expanded {
      max-height: unset;
      &:after {
        content: unset;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
