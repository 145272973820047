@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";

.references {
  .reference__row {
    padding: 0.6875rem 1rem;
    background-color: $colorBackground;
    border: 0.0625rem solid $colorPaleGreyBlue;
    border-radius: 0.25rem;
    margin: rem(10) 0;
  }
}
