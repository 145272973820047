@import "../../../styles/settings/variables";
.loader {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-25px, -25px, 0);
  &:after {
    content: " ";
    display: block;
    width: 34px;
    height: 34px;
    margin: 4px;
    border-radius: 50%;
    border: 4px solid $colorBlue;
    border-color: $colorBlue transparent $colorBlue transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  &--content {
    position: relative;
    min-height: 200px;

    &:after {
      position: relative;
      top: 50%;
    }
  }
  &--inline {
    position: relative;

    &:after {
      position: relative;
      top: 50%;
    }
  }
}

//used also in:
//  src\styles\form\input.scss
//  src\styles\form\button.scss
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}