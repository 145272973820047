@import "normalize";
@import "./settings/fonts";
@import "./settings/variables";
@import "./functions";
@import "./mixins/responsive";
@import "../assets/icons/icons";


body {
  font-family: $baseFontFamily !important;
  font-size: $defaultFontSize;
  line-height: $defaultLineHeight;
  background-color: $colorBackground;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  @include respond-max(medium-up) {
    overflow-y: auto;
  }
}

// box sizing fix for all elements
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

// selective reset
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

button {
  border: 0;
}

a {
  text-decoration: none;
  color: $colorBlue;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

.headline-1 {
  font-size: rem(32);
  line-height: rem(40);

  @include respond-max(medium-up) {
    font-size: rem(24);
    line-height: rem(32);
  }
}

.headline-2 {
  font-size: rem(24);
  line-height: rem(32);

  @include respond-max(medium-up) {
    font-size: rem(20);
    line-height: rem(24);
  }
}

.headline-3 {
  font-size: rem(20);
  line-height: rem(30);

  @include respond-max(medium-up) {
    font-size: rem(20);
    line-height: rem(24);
  }
}

.headline-4 {
  font-size: rem(16);
  line-height: rem(24);
}

.text-small {
  font-size: rem(12);
  line-height: rem(16);
}

.headline-1,
.headline-2,
.headline-3,
.headline-4 {
  margin: 0;
  padding: 0;
  display: block;
}


.sticky-inner-wrapper {
  @include respond-max(large-up) {
    position: static!important;
    transform: none !important;
    width: auto !important;
  }
}


@import "layout";
@import "modal";
@import "navigation";

@import "card";
@import "panel";
@import "list";
@import "form";
@import "table";
@import "tag";
@import "comments-table";
@import "user-box";
@import "sort-dropdown";
@import "tabs";
@import "tooltip";
@import "detail";
@import "react/react-dropzone";

@import "utils";
