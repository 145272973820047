.poll-count {
  &:disabled {
    color: black !important;
    cursor: default !important;
  }
}

.official-vote-button {
  padding: 10px;
}
