@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";

.personal-info {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  &__key {
    width: rem(120);
    padding: 0 0 rem(16);
    margin: 0;
    font-weight: 700;
  }

  &__value {
    width: calc(100% - 120px);
    padding: 0 0 rem(16);
    margin: 0;
    word-break: break-word;

    @include IE() {
      word-break: break-all;
    }
  }

  &__wrapper {
    padding-top: 0;
  }
}

.user {
  &-avatar__buttons {
    width: 100%;

    .button {
      margin: $gutter;
    }
  }

  &__crop {
    max-width: rem(300);
    height: rem(300);
    width: 50%;
    @include respond-max(medium-up) {
      height: rem(200);
    }
  }
}

.cropper {
  &-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    touch-action: none;
    user-select: none;

    img {
      display: block;
      height: 100%;
      image-orientation: 0deg;
      max-height: none !important;
      max-width: none !important;
      min-height: 0 !important;
      min-width: 0 !important;
      width: 100%;
    }
  }

  &-wrap-box,
  &-canvas,
  &-drag-box,
  &-crop-box,
  &-modal {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-wrap-box,
  &-canvas {
    overflow: hidden;
  }

  &-drag-box {
    background-color: #fff;
    opacity: 0;
  }

  &-modal {
    background-color: #000;
    opacity: 0.5;
  }

  &-view-box {
    display: block;
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51, 153, 255, 0.75);
    overflow: hidden;
    width: 100%;
  }

  &-dashed {
    border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;

    &.dashed-h {
      border-bottom-width: 1px;
      border-top-width: 1px;
      height: calc(100% / 3);
      left: 0;
      top: calc(100% / 3);
      width: 100%;
    }

    &.dashed-v {
      border-left-width: 1px;
      border-right-width: 1px;
      height: 100%;
      left: calc(100% / 3);
      top: 0;
      width: calc(100% / 3);
    }
  }

  &-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;

    &::before,
    &::after {
      background-color: #eee;
      content: ' ';
      display: block;
      position: absolute;
    }

    &::before {
      height: 1px;
      left: rem(-3);
      top: 0;
      width: rem(7);
    }

    &::after {
      height: rem(7);
      left: 0;
      top: rem(-3);
      width: 1px;
    }
  }

  &-face,
  &-line,
  &-point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  &-face {
    background-color: #fff;
    left: 0;
    top: 0;
  }

  &-line {
    background-color: #39f;

    &.line-e {
      cursor: ew-resize;
      right: rem(-3);
      top: 0;
      width: rem(5);
    }

    &.line-n {
      cursor: ns-resize;
      height: rem(5);
      left: 0;
      top: rem(-3);
    }

    &.line-w {
      cursor: ew-resize;
      left: rem(-3);
      top: 0;
      width: rem(5);
    }

    &.line-s {
      bottom: rem(-3);
      cursor: ns-resize;
      height: rem(5);
      left: 0;
    }
  }

  &-point {
    background-color: #39f;
    height: rem(5);
    opacity: 0.75;
    width: rem(5);

    &.point-e {
      cursor: ew-resize;
      margin-top: rem(-3);
      right: rem(-3);
      top: 50%;
    }

    &.point-n {
      cursor: ns-resize;
      left: 50%;
      margin-left: rem(-3);
      top: rem(-3);
    }

    &.point-w {
      cursor: ew-resize;
      left: rem(-3);
      margin-top: rem(-3);
      top: 50%;
    }

    &.point-s {
      bottom: rem(-3);
      cursor: s-resize;
      left: 50%;
      margin-left: rem(-3);
    }

    &.point-ne {
      cursor: nesw-resize;
      right: rem(-3);
      top: rem(-3);
    }

    &.point-nw {
      cursor: nwse-resize;
      left: rem(-3);
      top: rem(-3);
    }

    &.point-sw {
      bottom: rem(-3);
      cursor: nesw-resize;
      left: rem(-3);
    }

    &.point-se {
      bottom: rem(-3);
      cursor: nwse-resize;
      height: rem(20);
      opacity: 1;
      right: rem(-3);
      width: rem(20);

      @media (min-width: 768px) {
        height: rem(15);
        width: rem(15);
      }

      @media (min-width: 992px) {
        height: rem(10);
        width: rem(10);
      }

      @media (min-width: 1200px) {
        height: rem(5);
        opacity: 0.75;
        width: rem(5);
      }
    }

    &.point-se::before {
      background-color: #39f;
      bottom: -50%;
      content: ' ';
      display: block;
      height: 200%;
      opacity: 0;
      position: absolute;
      right: -50%;
      width: 200%;
    }
  }

  &-invisible {
    opacity: 0;
  }

  &-bg {
    background-color: rgba(0,0,0,0.5);
  }

  &-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }

  &-hidden {
    display: none !important;
  }

  &-move {
    cursor: move;
  }

  &-crop {
    cursor: crosshair;
  }

  &-disabled &-drag-box,
  &-disabled &-face,
  &-disabled &-line,
  &-disabled &-point {
    cursor: not-allowed;
  }
}