.notification-diagnostics {
    font-size: smaller;
    border: red 1px solid;
    padding: 5px;

    &__indent-1 {
        display: block;
        margin-left: 10px;
    }

    &__indent-2 {
        display: block;
        margin-left: 20px;
    }
}