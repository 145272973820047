@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.search-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: rem(12);
  width: 100%;
  border-bottom: rem(1) solid rgba(51, 77, 100, 1);
  &__input {
    width: 100%;
    margin-right: auto;
    padding-right: rem(4);
    line-height: rem(22);
    font-size: rem(14);
    color: $colorLight;
    text-overflow: ellipsis;
    border: 0 none;
    background: none;
    &:focus {
      outline: 0 none;
    }
  }
  &__btn {
    display: none;
    position: relative;
    height: $gap;
    width: $gap;
    min-width: $gap;
    padding: 0;
    background-color: $colorGrey;
    border-radius: 50%;
    &--opened {
      display: block;
    }
    &:after, &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: rem(2);
      width: rem(12);
      background: $colorDark;
      border-radius: rem(6);
    }
    &:after {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}