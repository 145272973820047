@import "settings/variables";
@import "functions";
@import "./mixins/responsive";

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.498039215686275);
  z-index: 100;
  // ------
  overflow: auto;
  padding-top: rem(120);
  padding-bottom: rem(120);

  &--center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal__tabindex-wrapper,
.modal__content-wrapper {
  position: relative;
  width: fit-content;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  top: 0;
  //overflow: auto;
  margin: auto;

  //-----------
  outline: none;
}

.modal__tabindex-wrapper {
  height: 100%;
  display: flex;
}

.modal__content {
  position: relative;
  width: rem(640);
  max-width: rem(640);
  height: auto;
  padding: $gap;
  margin: 0px auto;
  margin-bottom: rem(120);
  border-radius: $radius;
  border: 1px solid $colorGrey;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0666666666666667);
  background: $colorLight;
  outline: 0;

  @include respond-max(medium-up) {
    margin: $gap * 2 auto;
  }
  @include respond-max(medium-plus) {
    margin: $gap * 2 rem(12);
  }

  &--fixed-height {
    height: rem(540);
    margin: $gap * 2 auto;
    display: flex;
    flex-direction: column;
  }
  &--wide {
    width: rem(1000);
    max-width: rem(1000);
    @include respond-max(medium-up) {
      width: rem(640);
      max-width: rem(640);
    }
    // width: 70vw;
    // max-width: 70vw;
    // height: 100vh;
    // max-height: 100vh;
    // border: 0px;
    // border-radius: 0;
    // overflow: overlay;
  }
  &--big {
    width: rem(1130);
    max-width: rem(1200);
    @include respond-max(medium-up) {
      width: rem(640);
      max-width: rem(640);
    }
    // width: 70vw;
    // max-width: 70vw;
    // height: 100vh;
    // max-height: 100vh;
    // border: 0px;
    // border-radius: 0;
    // overflow: overlay;
  }

  p {
    margin: 0;
  }
}
.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include respond-max(medium-small-up) {
    display: block;
  }

  &__left {
    display: flex;
    align-items: center;
  }
}
.modal__controls {
  @include respond-max(medium-small-up) {
    margin-top: rem(24);
  }
}
.modal__footer {
  margin-top: auto;
}

.is-modal-opened {
  overflow: hidden;
}

.modal__close_button {
  display: inline-block;
  font-size: rem(42);
  transition: all 0.1s;
  position: absolute;
  top: rem(10);
  right: rem(10);
  cursor: pointer;
}

.modal__go_back_button {
  background-color: transparent;
  background-size: 115%;
  background-repeat: no-repeat;

  width: rem(25);
  height: rem(25);
  display: inline-block;
  transition: all 0.1s;
  // position: absolute;
  // top: rem(9);
  // left: rem(10);
  cursor: pointer;
}

.modal__title {
  color: $colorDark;
  margin-left: rem(32);
}

.slide {
  padding: 0;

  .modal__header {
    border-bottom: 1px solid $colorMediumGrey;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: $colorLight;
    width: 100%;
    height: rem(40);
    z-index: 1000;
  }

  .modal__body {
    padding: "0px 20px";
  }

  .modal__footer {
    margin: auto;
    max-width: 66.25rem;
    border-top: 1px solid $colorMediumGrey;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    background: $colorLight;
    width: 100%;
    height: rem(100);
  }

  .modal__tabindex-wrapper,
  .modal__content-wrapper {
    padding: 0;
    margin: 0 0 0 auto;
  }

  .modal__content {
    padding: 0 $gap;

    &--wide {
      width: 85vw;
      max-width: 85vw;
      height: 100vh;
      max-height: 100vh;
      border: 0px;
      border-radius: 0;
      overflow: overlay;
    }
    &--big {
      width: 85vw;
      max-width: 85vw;
      height: 100vh;
      max-height: 100vh;
      border: 0px;
      border-radius: 0;
      overflow: overlay;
    }
  }

  .after-slide {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  .before-slide {
    transform: translateX(100vw);
    transition: transform 0.3s ease-in-out;
  }
}

// .modal__close_button:hover {
//   // transform: rotate(45deg);

// }
