@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";

.input {
  width: 100%;
  height: rem(40);
  padding: rem(11) rem(16);
  background-color: $colorBackground;
  border: rem(1) solid $colorPaleGreyBlue;
  border-radius: rem(4);
  appearance: none;
  outline: 0;

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:focus {
    box-shadow: 0 0 rem(4) 0 $colorBlue;
  }

  .is-mobile & {
    font-size: rem(16);
  }

  &--validation_error {
    box-shadow: 0 0 rem(4) 0 $colorRed;
  }
  &--short {
    width: 50%;
    @include respond-max(medium-up) {
      width: 100%;
    }
  }

  &--date {
    width: rem(160);
    padding-right: 0;

    &::-webkit-inner-spin-button {
      display: none;
    }
  }
  &--time {
    width: rem(96);
    padding-right: 0;

    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  &::placeholder {
    color: #999999;
  }

  + .input {
    margin-left: rem(8);

    @include respond-max(medium-small-up) {
      display: block;
      margin-left: 0;
      margin-top: rem(16);
    }
  }
}

.radio,
.checkbox {
  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;
    outline: 0;
    opacity: 0;
  }

  label {
    margin-bottom: 0;
    display: inline-block;
  }
}

.inline-checkbox {
  position: relative;
  display: inline-block;
  padding-left: $gutter * 5;
  text-align: left;

  &--disabled {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.6;
  }
}

.checkbox-disabled {
  pointer-events: none;
  filter: grayscale(100%);
  opacity: 0.3;
}

.inline-checkbox__input {
  position: absolute !important;
  height: rem(1);
  width: rem(1);
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  &--off,
  &--on {
    position: relative;
    margin: 0 rem(4);
    .inline-checkbox & {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--off {
    display: block;
  }
  &--on {
    display: none;
  }
  &:checked {
    ~ .inline-checkbox__input {
      &--off {
        display: none;
      }
      &--on {
        display: block;
      }
    }
  }
  &:focus {
    ~ .inline-checkbox__input {
      &--on,
      &--off {
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          position: absolute;
          border-radius: 50%;
          box-shadow: 0 0 rem(4) 0 $colorBlue;
        }
      }
    }
  }
  &--awaiting {
    margin: 0px 4px;
    padding-right: 14px;
    &:after {
      content: " ";
      display: inline-block;
      vertical-align: top;
      width: 18px;
      height: 18px;
      margin: 0px;

      border-radius: 50%;
      border: 2px solid $colorBlue;
      border-color: $colorBlue transparent $colorBlue transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

.radio {
  display: flex;
  align-items: center;
}

.input-file {
  &--inline {
    display: inline-block;
    margin: 0 $gutter 0 0;
    vertical-align: middle;
  }

  &__button {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-align: center;

    &.focused {
      border-radius: $gutter;
      box-shadow: 0 0 rem(8) 0 $colorBlue;
    }

    input[type="file"] {
      position: absolute;
      left: rem(-5);
      top: 0;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

::-webkit-file-upload-button {
  cursor: pointer;
}
