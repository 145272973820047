@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.date-thumbnail {
    $self: &;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: rem(48);
    min-width: rem(48);
    height: rem(48);
    margin-right: rem(16);
    border-radius: $radius-small;
    text-align: center;
    background-color: $colorLightSecondary;

    &--big {
        width: rem(64);
        min-width: rem(64);
        height: rem(64);
        border-radius: $radius;
        background-color: $colorLightSecondary;

        &--extended {
            height: rem(72);
        }
    }

    
    &--extended {
        height: rem(60);
    }

    &__number {
        font-size: rem(24);
        line-height: rem(24);
        font-weight: 700;

        #{$self}--big & {
            font-size: rem(28);
            line-height: rem(28);
        }
    }

    &__month {
        font-size: rem(12);
        line-height: rem(16);
        font-weight: 400;

        #{$self}--big & {
            font-size: rem(16);
            line-height: rem(20);
        }
    }

    &__year {
        font-size: rem(10);
        line-height: rem(12);
        font-weight: 400;

        #{$self}--big & {
            font-size: rem(12);
            line-height: rem(16);
        }
    }
}