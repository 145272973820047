@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.toolbar-tool {
  align-self: center;

  // SVG/TEXT color white
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%)
    hue-rotate(314deg) brightness(106%) contrast(101%);

  button {
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  .icon {
    width: rem(31);
    height: rem(31);
  }
}
