@import "./settings/variables";

table {
  &.expandable {
    cursor: pointer;
    // width: 100%;
  }
}

.table-wrapper {
  position: relative;
  @include respond-max(large-up) {
    &:after {
      content: " ";
      width: $gap/2;
      height: 100%;
      position: absolute;
      right: rem(-8);
      top: 0;
      bottom: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}
.table-content {
  margin: 0 rem(-8) rem(-8) rem(-8);
  padding: 0 rem(8) rem(8) rem(8);

  .is-mobile & {
    -webkit-overflow-scrolling: touch;
  }
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  &__header,
  &__row {
    padding: 0;
    color: #000;
  }

  &__header {
    button {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__toggler {
    &:hover {
      .table__row.expanded {
        box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.1);
        + .table__row {
          box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &__row {
    &.collapsed + .table__row:not(.collapsed) {
      display: none;
    }
    &.expanded {
      + .table__row:not(.collapsed) {
        display: table-row;
        td {
          padding-top: 0;
        }
      }
      td {
        border-bottom-color: transparent;
      }
    }
    &.collapsed:hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
    &:not(.expanded):not(.collapsed) {
      cursor: initial;
    }
  }

  th,
  td {
    padding: 16px 0.5rem 16px 0;
    text-align: left;
    border: none;
    border-bottom: 1px solid #e4e4e4;
    &.table__toggler-row {
      padding: 0;
      border: none;
    }
  }

  th {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  td {
    text-align: left;
    > span {
      display: block;
    }
  }
}
