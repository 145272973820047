@import "../settings/variables";

.dropzone {
  position: relative;
  max-width: rem(640);
  padding: rem(48);
  background: transparent;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: rem(8);
    background: linear-gradient(90deg, $colorGrey 100%, transparent 100%) top left no-repeat,
    linear-gradient(90deg, transparent calc(4px / 2), $colorGrey calc(4px / 2), $colorGrey calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) top center repeat-x,
    linear-gradient(90deg, $colorGrey 100%, transparent 100%) top right no-repeat,
    linear-gradient(0deg, $colorGrey 100%, transparent 100%) top left no-repeat,
    linear-gradient(0deg, transparent calc(4px / 2), $colorGrey calc(4px / 2), $colorGrey calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) center left repeat-y,
    linear-gradient(0deg, $colorGrey 100%, transparent 100%) bottom left no-repeat,
    linear-gradient(90deg, $colorGrey 100%, transparent 100%) bottom left no-repeat,
    linear-gradient(90deg, transparent calc(4px / 2), $colorGrey calc(4px / 2), $colorGrey calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) bottom center repeat-x,
    linear-gradient(90deg, $colorGrey 100%, transparent 100%) bottom right no-repeat,
    linear-gradient(0deg, $colorGrey 100%, transparent 100%) top right no-repeat,
    linear-gradient(0deg, transparent calc(4px / 2), $colorGrey calc(4px / 2), $colorGrey calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) center right repeat-y,
    linear-gradient(0deg, $colorGrey 100%, transparent 100%) bottom right no-repeat;
    background-size: 8px 1px, calc(8px + 4px) 1px, 8px 1px, 1px 8px, 1px calc(8px + 4px), 1px 8px;
  }

  &--active {
    &:after {
      background: linear-gradient(90deg, $colorBlue 100%, transparent 100%) top left no-repeat,
      linear-gradient(90deg, transparent calc(4px / 2), $colorBlue calc(4px / 2), $colorBlue calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) top center repeat-x,
      linear-gradient(90deg, $colorBlue 100%, transparent 100%) top right no-repeat,
      linear-gradient(0deg, $colorBlue 100%, transparent 100%) top left no-repeat,
      linear-gradient(0deg, transparent calc(4px / 2), $colorBlue calc(4px / 2), $colorBlue calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) center left repeat-y,
      linear-gradient(0deg, $colorBlue 100%, transparent 100%) bottom left no-repeat,
      linear-gradient(90deg, $colorBlue 100%, transparent 100%) bottom left no-repeat,
      linear-gradient(90deg, transparent calc(4px / 2), $colorBlue calc(4px / 2), $colorBlue calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) bottom center repeat-x,
      linear-gradient(90deg, $colorBlue 100%, transparent 100%) bottom right no-repeat,
      linear-gradient(0deg, $colorBlue 100%, transparent 100%) top right no-repeat,
      linear-gradient(0deg, transparent calc(4px / 2), $colorBlue calc(4px / 2), $colorBlue calc(4px / 2 + 8px), transparent calc(4px / 2 + 8px)) center right repeat-y,
      linear-gradient(0deg, $colorBlue 100%, transparent 100%) bottom right no-repeat;
      background-size: 8px 1px, calc(8px + 4px) 1px, 8px 1px, 1px 8px, 1px calc(8px + 4px), 1px 8px;
    }
  }

  .headline-4 {
    margin-bottom: rem(24);
  }
}

.dropzone-separator {
  position: relative;
  display: block;
  width: 100%;
  max-width: rem(640);
  margin: 0 auto;
  text-align: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: calc(50% - 24px);
    height: rem(1);
    left: 0;
    top: 50%;
    margin-top: rem(-1);
    background-color: $colorGrey;
  }
  &:after {
    right: 0;
    left: auto;
  }
}