@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";


.select-box,
.react-dropdown-select.select-box {
  width: 100%;
  background-color: $colorBackground;
  border: 1px solid $colorPaleGreyBlue;
  border-radius: $radius-small;
  padding: rem(10) $gap/2;

  .react-dropdown-select-dropdown {
    background-color: $colorBackground;
    border: 1px solid $colorPaleGreyBlue;
    border-radius: $radius-small;
  }

}
.select-wrapper {
  display: flex;

  > div:not(.select-box) {
    width: 50%;
  }

  @include respond-max(medium-up) {
    display: initial;

    > div:not(.select-box) {
      width: 100%;
    }
  }
}

.select {
  position: relative;
  width: 100%;
  height: rem(40);
  padding: rem(8) $gap/2;
  background-color: $colorBackground;
  border: 1px solid $colorPaleGreyBlue;
  border-radius: $radius-small;

  &.focused {
    box-shadow: 0 0 rem(4) 0 $colorBlue;
  }

  .is-mobile & {
    padding: rem(7) $gap/2;
    font-size: rem(16);
  }

  &__text {
    display: inline-block;
    width: calc(100% - #{rem(50)});
    overflow: hidden;

    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  select {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    padding: 0 $gap/2;
    appearance: none;
    outline: 0;
    z-index: 1;
  }

  .icon {
    float: right;
    transform: rotate(90deg);
    width: $gap;
    height: $gap;
    margin-top: -2pxs;
  }
}