@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";

.label {
  font-weight: 700;

  &__wrapper {
    position: relative;
    display: block;
    padding: 0 0 rem(10) 0;
  }
}

.label-row {
  margin-bottom: rem(10);
}

.field-label {
  display: block;
  font-weight: 700;
  &:not(.visuallyhidden) {
    + .select,
    + select,
    + .input,
    + input,
    + textarea,
    + .textarea,
    + .datepicker {
      margin-top: rem(10);
    }
  }
}