@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.search-box {
  position: relative;
  overflow: hidden;
  padding: rem(4);
  &__button {
    background-color: transparent;
    margin-right: rem(8);
    .icon {
      width: rem(32);
      height: rem(32);
    }
  }

  &__input {
    width: rem(312);
    max-width: rem(312);
    height: rem(32);
    padding: rem(10) rem(16);
    background-color: transparent;
    border: rem(1) solid transparent;
    border-radius: rem(4);
    appearance: none;
    outline: 0;
    text-align: left;

    &:focus {
      border-color: $colorGrey;
      box-shadow: 0 0 rem(4) 0 $colorBlue;
    }

    @include respond-max(medium-up) {
      width: calc(37vw);
      max-width: calc(37vw);
    }

    .is-mobile & {
      font-size: rem(16);
      padding: rem(8) rem(16);
    }
  }

  &__content {
    position: relative;
    display: flex;
    width: rem(364);
    transform: translateX(rem(320));
    transition: transform 0.5s ease-in-out;

    @include respond-max(medium-up) {
      width: calc(40vw + 44px);
      transform: translateX(40vw);
    }
  }

  &--opening,
  &--opened {
    .search-box__content {
      transform: translateX(0);
    }
  }
  &--opened {
    overflow: visible;
    .search-box__input {
      &:not([value=""]) {
        border-color: $colorGrey;
      }
    }
  }

}

.react-autosuggest__container {
  position: relative;

  &--open {
    .search-box__input {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  margin-top: rem(1);
  background-color: $colorLight;
  border: 1px solid transparent;
  border-bottom-left-radius: rem(4);
  border-bottom-right-radius: rem(4);
  width: 100%;

  &--open {
      display: block;
      border-color: $colorGrey;
      position: absolute;
      z-index: 2;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-autosuggest__suggestion {
  padding: 0 rem(16);
  cursor: pointer;
  > div {
    padding: rem(12) 0;
    border-bottom: 1px solid $colorBackground;
  }

  &:hover {
    background-color: $colorBackground;
  }

  &:last-child {
    border-bottom-right-radius: rem(4);
    border-bottom-left-radius: rem(4);
    > div {
      border-bottom: none;
    }
  }
  &--highlighted {
    background-color: $colorBackground;
  }
}