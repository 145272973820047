@import "../../../styles/settings/variables.scss";
@import "../../../styles/functions.scss";
@import "../../../styles/mixins/responsive.scss";

.toggle-switch {
  position: relative;
  width: 60px;
  min-width: 60px;
  height: 30px;
  background-color: $colorMediumGrey;
  border-radius: 15px;
  cursor: pointer;

  &.on {
    background-color: $colorBlue;

    .switch-handle {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  &.off {
    background-color: #aaa;

    .switch-handle {
      left: 2px;
      transform: translateX(0);
    }
  }

  .switch-handle {
    position: absolute;
    top: 2px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }
}
