.poll {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .remove-button {
    filter: invert(21%) sepia(34%) saturate(4426%) hue-rotate(327deg)
      brightness(97%) contrast(101%);
    background-color: transparent;
    border: none;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.poll-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .poll-list__element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    // border-bottom: 1px solid #eee;
  }
}

.poll-footer {
  padding-top: 0.5rem;
  font-size: 12px;
  color: #666;
}
