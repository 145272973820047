@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.committee-content {
  &.panel {
    padding: rem(8) rem(24);
  }

  @include respond-max(medium-up) {
    position: relative;

    &:after {
      content: ' ';
      width: $gap/2;
      position: absolute;
      right: $gap;
      top: $gap;
      bottom: $gap;
      background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    }
  }

  &__list-wrapper {
    overflow-x: auto;

    .is-mobile & {
      -webkit-overflow-scrolling: touch;
    }

    .name {
      width: rem(140);
      min-width: rem(140);
      max-width: rem(140);
    }
    .last {
      width: rem(100);
      min-width: rem(100);
      text-align: right;
      
      @include respond-max(medium-up) {
        width: rem(85);
        min-width: rem(85);
      }
    }
    .label {
      width: calc(100% - #{rem(240)});
      min-width: rem(250);
      padding-left: rem(20);

      @include respond-max(medium-up) {
        min-width: rem(430);
      }
    }

    .counter {
      display: inline-block;
      height: rem(24);
      padding: 0 $gap;
      vertical-align: text-top;
    }
  }

  .list__element {
    line-height: rem(24);
    padding: rem(16) 0;

    @include IE() {
      width: auto;
      min-width: 100%;
    }
  }
  .list__header {
    padding: $gap 0 $gap 0;
    color: $colorDark;

    .icon {
      display: block;
      float: left;
      margin-right: $gap;
    }
  }
}