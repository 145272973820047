@import "../../../../../styles/settings/variables.scss";

.participant-bar {
  display: flex;
  align-items: center;
  background-color: $colorBackground;
  padding: 1rem 1rem;
  border-radius: 8px;

  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .radio-option {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    .radio-label {
      color: $colorDarkGray;
    }
  }

  .participants-count {
    margin-left: auto;

    &:disabled {
      cursor: default;
      color: $colorDarkGray;
    }
  }
}
