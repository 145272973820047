@import "./settings/variables";
@import "functions";
@import "./mixins/responsive";

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: rem(16);
  vertical-align: bottom;
  &__icon {
    opacity: 0.5;
  }
  &__content  {
    display: none;
    position: absolute;
    left: 50%;
    bottom: rem(-8);
    min-width: rem(240);
    padding: rem(16);
    margin: 0;
    background: $colorLight;
    border: rem(1) solid $colorGrey;
    border-radius: rem(8);
    transform: translateX(-50%) translateY(100%);
    z-index: 100;
    &:after {
      content: "";
      display: block;
      height: rem(12);
      width: rem(12);
      position: absolute;
      top: rem(-6);
      left: 50%;
      background: $colorLight;
      border-width: rem(1);
      border-style: solid;
      border-color: $colorGrey transparent transparent $colorGrey;
      transform: translateX(-50%) rotate(45deg);
      z-index: 100;
    }
  }
  &:hover {
    .tooltip__content {
      display: block;
    }
  }
}