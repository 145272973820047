@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";

.deadlines {
    &__sidebar {
        .checkbox__group {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: rem(16) 0;
            margin: rem(16) 0;
            border-top: 1px solid $colorGrey;
            border-bottom: 1px solid $colorGrey;

            input {
                width: rem(20);
                height: rem(20);
                margin: 0 rem(16) 0 rem(2) !important;
            }

            label {
                font-size: rem(14);
                line-height: rem(20);
            }
        }
    }

    &__info-card {
        
        display: flex;
        margin-left: auto;
        width: rem(550);
        margin-top: rem(-50);
        margin-bottom: rem(-30);

        @include respond-max(xlarge-up) {
            margin-top: rem(-10);
            margin-bottom: rem(0);
        }

        @include respond-max(large-up) {
            display: none;
        }

        &--mobile {
            display: none;
            @include respond-max(large-up) {
                display: block;
                width: 100%;
            }
        }
    }

    &__nothing-to-show {
        margin-top: rem(40);
    }
}