@import "settings/variables";
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 rem(16) 0;
  padding: rem(16);
  border: 1px solid $colorGrey;
  border-radius: $radius;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
  background-color: $colorLight;

  &__mark {
    position: absolute;
    width: rem(4);
    height: 100%;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(15, 178, 103);
    margin-left: rem(-16);
    margin-top: rem(-16);
    border-radius: $radius 0 0 $radius;
  }

  &--with-offset {
    .card__content,
    .card__attachments {
      margin-left: rem(64);
    }
  }

  &__title {
    display: block;
    margin: rem(40) 0 rem(24) 0;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 700;

    &:first-child {
      margin: rem(8) 0 rem(24) 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__headings {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: rem(40);

    &__labels {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .icon {
        margin-right: 5px;
      }
    }
  }

  &__heading-2 {
    font-size: rem(16);
    font-weight: 700;
    line-height: rem(24);
    color: $colorBlue;
    word-break: break-word;
  }

  &__content {
  }

  &__description {
    margin: rem(16) 0;
    white-space: pre-wrap;

    p {
      margin: 0;
    }
  }

  &__attachments {
    display: flex;
    flex-direction: row;

    margin: 0 0 rem(8);

    & > a {
      margin: 0 rem(32) 0 0;

      &:last-child {
        margin: 0;
      }
    }
    .icon {
      margin-right: rem(8);
    }
  }

  &__menu {
    position: absolute;
    top: rem(-8);
    right: 0;
    width: rem(24);
    height: rem(24);
    padding: 0;
    cursor: pointer;

    .button {
      margin: 0;
    }
  }

  &__ribbon {
    position: absolute;
    right: rem(48);
    top: rem(-4);
  }

  &__empty {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__votes {
    display: inline-block;
    height: auto;

    margin-left: auto;
    &--mark {
      width: 40px !important;
      &::after {
        content: "";
        margin-left: 8px;
        display: inline-block;
        width: 6px;
        height: 11px;
        border-bottom: 2px solid $colorLight;
        border-right: 2px solid $colorLight;

        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &-num {
      display: inline-block;
      border-radius: 2px;
      height: 24px;
      width: 24px;
      text-align: center;
      margin-left: 5px;

      p {
        display: inline;
        font-size: 12px;
        font-weight: bold;
        font-family: sans-serif;
        color: white;
        text-align: center;
        vertical-align: middle;
        line-height: 24px;
      }
    }

    &--yes {
      background-color: #0fb267;
    }
    &--no {
      background-color: #ff5454;
    }
    &--confirm {
      background-color: #0eb266;
    }
    &--revise {
      background-color: #ffaa54;
    }
    &--withdraw {
      background-color: #ff5454;
    }
    &--abstain {
      background-color: #7f909e;
    }
  }

  &__highlight {
    box-shadow: 0px 0px 10px $colorBlue;
  }
  &__inactive {
    opacity: 0.5;
    pointer-events: none;
  }
}
