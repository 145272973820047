@import "./settings/variables";
@import "./functions";
@import "./mixins/responsive";
.datepicker {

  display: inline-block;

  + .datepicker {
    margin-left: rem(8);

    @include respond-max(medium-small-up) {
      margin-left: 0;
      margin-top: rem(16);
      display: block;
    }
  }

  &--width-fix > .react-datepicker-wrapper,
  &--width-fix > .react-datepicker-wrapper > .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 2;
  }
}