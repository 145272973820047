@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.sidebar-filter__form {
  input[type="text"] {
    height: rem(40);
    background-color: $colorLight;
    border: 1px solid $colorPaleGreyBlue;
  }

  .input-wrapper {
    padding: rem(16) 0;
    border-bottom: 1px solid $colorGrey;

    &:first-child {
      padding-top: 0;
    }
  }

  .select {
    height: rem(40);
    background-color: $colorLight;

    & > select {
      height: rem(40);
    }
  }

  .button {
    height: rem(40);
    margin: rem(16) 0 0 0;

    &--link {
      margin-top: rem(8);
    }
  }

  .sidebar-filter__link {
    height: auto;
    margin-top: 0;
  }

  .notification-filter-option {
    display: flex;
    .toggle-switch {
      width: 45px;
      min-width: 45px;
      max-height: 24px;

      .switch-handle {
        width: 20px;
        height: 20px;
      }
    }

    .inline-checkbox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 1rem;
    }
  }
}
