@import "../../../styles/settings/variables";
@import "../../../styles/functions";
@import "../../../styles/mixins/responsive";

.voter {
  & > h3 {
    margin: rem(24) 0 rem(24);
  }

  .voting-bar {
    width: 100%;
    position: relative;
    height: rem(40);
    background-color: rgb(242, 244, 245);
    border-radius: $radius-small;
    overflow: hidden;

    & > span {
      position: absolute;
      top: rem(10);
      left: rem(16);
      margin: 0;
      font-weight: 700;
      z-index: 1;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      margin-bottom: $gutter;
    }

    &__votes {
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      height: 100%;
      padding-top: rem(8);
      padding-bottom: rem(8);
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.8s ease-out;

      &.yes {
        background-color: #0eb266;
      }
      &.no {
        background-color: #ff5454;
      }
      &.confirm {
        background-color: #0eb266;
      }
      &.revise {
        background-color: #ffaa54;
      }
      &.withdraw {
        background-color: #ff5454;
      }
      &.abstain {
        background-color: rgba(127, 144, 158, 1);
      }
    }

    &__vote-count {
      position: absolute;
      right: rem(15);
      bottom: 0%;
      height: rem(40);
      line-height: rem(40);

      p {
        margin: 0;
      }

      // span {
      //     margin-left: rem(15);
      //     color: #00f;
      //     cursor: pointer;
      // }
    }
  }
}
