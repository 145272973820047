@import "../functions";

$font-size-base: 16;
$font-size-base-px: $font-size-base * 1px;

$mq-base: $font-size-base;

/* TYPOGRAPHY */
$baseFontFamily: 'Open Sans', sans-serif;

$defaultFontSize: rem(14);
$defaultLineHeight: rem(20);

$headerFontSizeMain: rem(24);
$headerLineHeightMain: rem(32);
$headerFontSizeSecond: rem(20);
$headerLineHeightSecond: rem(24);

/* COLORS */
$colorBackground:#f2f4f5;
$colorLight: #fff;
$colorDark: #000;
$colorDarkGray: #333333;
$colorBlue: #077bfb;
$colorDarkBlue: #00203D;
$colorMediumBlue: #88BFE8;
$colorGrey: #D8D8D8;
$colorMediumGrey: #AEB2B2;
$colorRed: #B8213D;
$colorGreen: #207c4e;

$colorPaleDarkBlue: #334D64;
$colorPaleGreyBlue: #7F909E;
$colorLightSecondary: #f2f4f5;

$colorBrightGrey: #606060;

$colorPaleBlue: #c4dff4;
$colorLightTertiary: #e6f1fe;


/* GAPS, SPACES, RADIUS, ETC. */
$gap: rem(24);
$gutter: rem(8);
$gutter2: $gutter * 2;
$gutter3: $gutter * 3;
$radius: rem(8);
$radius-small: rem(4);

$breakpoints: (
    small-up: 360px,
    medium-small-up: 480px,
    medium-plus: 664px,
    medium-up: 768px,
    large-medium-up: 820px,
    large-up: 1024px,
    xlarge-up: 1280px
);





