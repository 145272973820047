@mixin respond-max($width) {
	$width-em: em(strip-units(bp($width)), $mq-base);

	@media screen and (max-width: $width-em) {
		@content;
	}
}

@mixin IE-width($width) {
	$width-em: em(strip-units(bp($width)), $mq-base);

	@media screen and (max-width: $width-em) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

@mixin IE {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}