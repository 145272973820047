@import "/settings/variables";
@import "functions";
@import "./mixins/responsive";

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.u-no-h-pading {
  padding-left: 0;
  padding-right: 0;
}

.u-no-v-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.u-no-padding {
  padding: 0 !important;
}

.u-no-margin-mobile {

  @include respond-max(medium-up) {
    margin: 0 !important;
  }
}

.u-text-light {
  color: $colorBrightGrey;
}

.u-text-medium-dark {
  color: #333;
}

.u-text-blue {
  color: $colorBlue;
}

.u-text-bold {
    font-weight: 700;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-normal {
  font-weight: 400;
}

.u-align-left {
  text-align: left;
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-flex {
  display: flex;
}
.u-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.u-offset-top-mobile {
  @include respond-max(medium-up) {
    margin-top: $gap/2 !important;
  }
}

.u-only-small {
  display: none;

  @include respond-max(medium-up) {
    display: block;
  }
}

.u-medium-up {
  display: block;

  @include respond-max(medium-up) {
    display: none;
  }
}

.hidden {
  display: none;
}

.u-row {
  width: auto;
  display: flex;
  align-items: center;
  & > * {
    margin-right: $gap;
  }
}

.u-row-medium {
  text-align: left;
  width: auto;
  display: flex;
  align-items: center;
  & > * {
    margin-right: $gap;
  }

  @include respond-max(medium-up) {
    text-align: center;
    display: block;

    & > * {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.u-full-width {
  width: 100%;
}