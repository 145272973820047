.sidebar {
  &__markAll {
    line-height: 16px;
    &--ready {
      &::before {
        content: " ";
        display: inline-block;
        background: url("../../../../../assets/icons/icons8-eye.svg");
        width: 16px;
        height: 16px;

        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
}

.ml-10 {
  margin-left: 10px;
}
