.graybox-label {
  inline-size: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  background-color: #f2f4f5;
}
