.members-container {
  // background: #fff;
  // border-radius: 8px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // padding: 20px;
  // width: 100%;
  // max-width: 400px; // Adjust width as necessary
  // font-family: "Arial", sans-serif;

  .members-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      color: #333;
    }

    .send-mail-btn {
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      background-color: #f2f2f2;
      color: #333;
      cursor: pointer;
      &:hover {
        background-color: darken(#f2f2f2, 10%);
      }
    }
  }

  .members-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .member-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding: 10px 0;

      &:last-child {
        border-bottom: none;
      }

      .member-info {
        display: flex;
        align-items: center;

        .member-avatar {
          // width: 40px;
          // height: 40px;
          // border-radius: 20px;
          margin-right: 10px;
        }

        .member-details {
          display: flex;
          flex-direction: column;

          .member-name {
            font-weight: bold;
            color: #333;
          }

          .member-email {
            color: #666;
          }
        }
      }

      .member-status {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .close-btn {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    &:hover {
      background-color: darken(#007bff, 10%);
    }
  }
}
