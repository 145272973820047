@import "./settings/variables";
@import "./functions";
@import "./mixins/responsive";

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: min-content;

  @include IE() {
    min-width: 100%;
  }

  &--offset-top {
    margin-top: $gap;
  }

  &--short {
    width: 50%;

    @include respond-max(medium-up) {
      width: 100%;
    }

    @include IE() {
      min-width: 50%;
    }
  }

  &--dark {
    .list__element {
      color: $colorDark;
    }
  }

  a {
    display: block;
    // color: $colorBrightGrey;
  }

  &__wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 100%;
    overflow-x: auto;

    @include respond-max(xlarge-up) {
      overflow-x: initial;
    }
  }

  &__element {
    width: 100%;
    min-width: min-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $gap/2 0;
    color: $colorBrightGrey;
    background-color: transparent;
    border-bottom: 1px solid $colorGrey;
    text-align: left;
    vertical-align: middle;

    &:last-child {
      border-bottom-color: transparent;
    }
  }
  &__pointer-cursor {
    cursor: pointer;
  }
  &--links {
    margin-top: rem(16);
    button {
      padding: 0;
      display: inline-block;
      background: none;
    }
  }
}
