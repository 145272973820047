@import "settings/variables";
@import "functions";
@import "./mixins/responsive";

.sortable {
  position: relative;

  &:after  {
    width: rem(8);
    height: rem(8);
    position: relative;
    top: 0;
    right: rem(-4);
  }

  &-asc:after {
    content: '\25B4';
  }

  &-dsc:after {
    content: '\25BE';
  }
}

.sort-dropdown {
  position: absolute;
  width: auto;
  min-width: rem(194);
  right: 0;
  top: $gap * 1.5;
  margin: 0;
  background-color: $colorLight;
  white-space: nowrap;
  text-align: left;
  font-weight: 400;

  z-index: 1;

  &__title {
    padding: rem(16) rem(16) rem(8) rem(16);
    border-bottom: 1px solid $colorGrey;
    margin-bottom: 0;

    font-size: rem(12);
    line-height: rem(20);
    font-weight: 400;
    color: $colorBrightGrey;
    text-align: left;
    text-transform: uppercase;
  }

  .group {
    padding: rem(12) rem(24) rem(24) rem(12);

    &--underlined {
      padding-bottom: rem(12);
    }

    input[type="radio"] {
      margin-right: rem(8);
    }
  }

  span {
    color: $colorBlue;
    font-size: rem(14);
    cursor: pointer;
  }

  .committee & {
    left: 0;
    right: auto;
  }
}