@import "./settings/variables";
@import "functions";

.tabs {
  position: absolute;
  display: flex;
  width: 100%;
  top: rem(-40);
  left: rem(-1);
}

.tab {
  display: block;
  position: relative;
  height: rem(40);
  padding: rem(8) $gap;
  margin-right: $gap/3;
  border: 1px solid transparent;
  border-bottom-color: $colorGrey;
  list-style: none;
  cursor: pointer;
  color: $colorDark;
  background-color: $colorPaleBlue;
  border-radius: $radius $radius 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:last-child {
    margin-right: 0;
  }
  &__label {
    padding-left: rem(16);
    font-size: rem(12);
    font-weight: 700;
  }
}

.tab--selected {
  background: $colorLight;
  border-color: $colorGrey;
  border-bottom-color: transparent;
}

.tab--disabled {
  color: $colorBrightGrey;
  cursor: default;
}

.tab:focus {
  box-shadow: 0 0 rem(9) 0 $colorBlue;
}