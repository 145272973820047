@import "./settings/variables";
@import "./functions";
@import "./mixins/responsive";

.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-items: stretch;

  background-color: $colorBackground;

  @include respond-max(medium-up) {
    min-height: 0;
    // overflow: hidden;
  }

  .heading-text {
    text-align: left;
    display: block;
    padding: 0;
    padding-top: 0.5rem;
  }
  .heading-icon {
    display: none;
    font-size: rem(20);
    font-weight: 700;
  }

  &__logo {
    // flex: 0 0 auto;
    width: fit-content;

    // margin: 0 auto;
    margin: rem(24) rem(32);
    display: block;

    // text-align: center;
    background-color: $colorDarkBlue;

    @include respond-max(medium-up) {
      margin: rem(16) auto;
    }
  }

  &__panel {
    min-height: 100vh;
    height: inherit;
    transition: transform 0.5s ease-in-out;

    &--left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      position: fixed;
      left: 0;
      top: 0;

      min-width: rem(140);
      width: rem(240);
      height: 100vh;
      background-color: $colorDarkBlue;
      z-index: 2;

      @include respond-max(medium-up) {
        width: rem(260);
        min-width: rem(260);
        opacity: 0;
        transition: opacity 0s 0.5s ease-in-out;

        z-index: 0;
      }

      .menu--opened & {
        @include respond-max(medium-up) {
          opacity: 1;
          transition: opacity 0s 0s ease-in-out;
        }
      }

      &--footer {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
        padding: rem(16) rem(16);
      }
    }

    &--right {
      width: 100%;
      min-width: 100%;
      height: 100%;

      padding-top: rem(60);
      padding-left: rem(240);
      border-left: 1px solid $colorGrey;

      position: relative;

      background-color: $colorBackground;
      transition: all 0.4s ease-in-out;

      display: flex;
      flex-direction: column;

      z-index: 1;

      .menu--opened & {
        transform: translateX(0);

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.07);
          z-index: 3;
        }

        @include respond-max(medium-up) {
          transform: translateX(260px);
        }
      }

      @include respond-max(medium-up) {
        padding-top: rem(24);
        width: 100%;
        min-width: 100%;
        padding-left: rem(0);
      }
    }
  }

  &__top-bar {
    position: fixed;
    top: 0;
    right: 0;

    width: calc(100% - 260px);
    padding: rem(8) rem(8) rem(8) rem(24);

    display: none;
    justify-content: flex-end;
    align-items: center;
    height: rem(48);
    background-color: $colorLight;
    border-bottom: 1px solid $colorGrey;
    z-index: 2;
    transition: transform 0.4s ease-in-out;

    @include respond-max(medium-up) {
      display: flex;
      width: 100%;
      padding: 0 $gutter;
      justify-content: flex-start;
    }

    .menu--opened & {
      transform: translateX(260px);

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.07);
        z-index: 1;
      }

      .heading-text {
        display: block;
      }
      .heading-icon {
        display: none;
      }

      @include respond-max(medium-up) {
        .heading-text {
          display: none;
        }
        .heading-icon {
          display: block;
        }
      }
    }
  }
  &__header {
    // position: -webkit-sticky;
    // position: -moz-sticky;
    // position: -ms-sticky;
    // position: -o-sticky;
    // position: sticky;

    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 240px);

    z-index: 2;
    background-color: $colorLight;
    border-bottom: 1px solid $colorGrey;

    .layout__heading {
      display: flex;
      justify-content: space-between;
      // padding: rem(32) rem(16) rem(16);
      padding: rem(16) rem(16) rem(16);
    }
  }

  &__heading {
    position: relative;
    padding: rem(32) 0 rem(16);
    margin: 0 auto;
    color: $colorDark;
    font-size: 1rem;
    // line-height: $headerLineHeightSecond;
    // font-weight: 700;
    text-align: center;
    background-color: transparent;
    z-index: 10;

    @include respond-max(medium-up) {
      padding: rem(14) 0;
      margin: 0 auto 0 0;
      color: $colorBlue;
      font-size: rem(14);
      line-height: rem(20);
    }

    @include respond-max(medium-up) {
      padding: rem(14) 0;
      margin: 0 auto 0 0;
      color: $colorBlue;
      font-size: rem(14);
      line-height: rem(20);
    }
  }
  button.layout__heading {
    position: relative;
    max-width: calc(100% - 90px);
    width: auto;

    text-align: left;
    overflow: hidden;

    &:after {
      display: none;
    }

    @include respond-max(medium-small-up) {
      width: calc(100% - 90px);

      .heading-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: calc(100% - 1px);
          width: rem(24);
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }
  }

  &.menu--opened button.layout__heading:after {
    display: none;
  }

  &__title-wrapper,
  &__title {
    max-width: rem(1060);
    margin: rem(24) auto rem(24) auto;

    font-size: $headerFontSizeMain;
    line-height: $headerLineHeightMain;
    font-weight: 700;

    @include respond-max(medium-up) {
      margin: rem(32) auto rem(16) auto;
    }

    @include respond-max(medium-small-up) {
      max-width: rem(560);
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;

    @include respond-max(medium-up) {
      // flex-direction: column;
      // justify-content: left;
      > div {
        margin-top: rem(16);
      }
    }
  }

  &__label-wrapper {
    max-width: rem(1060);
    margin: rem(24) auto rem(24) auto;

    @include respond-max(medium-up) {
      margin: rem(32) auto rem(16) auto;
    }

    @include respond-max(medium-small-up) {
      max-width: rem(560);
    }
  }

  &__title-group {
    display: flex;
    align-content: space-between;
    padding-left: rem(24);

    &:first-child:nth-last-child(n + 1) {
      padding-left: 0;
    }

    @include respond-max(medium-up) {
      padding-left: 0;
    }

    > .group--inline {
      min-width: 0%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.group--btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-shrink: 0;
      padding-left: rem(24);

      &:first-child:nth-last-child(n + 1) {
        padding-left: 0;
      }

      @include respond-max(medium-up) {
        padding-left: 0;
      }
    }

    h2 + h3 {
      margin-top: rem(8);
      word-break: break-word;
    }
  }

  &__subtitle {
    display: block;
    margin: rem(40) 0 rem(24) 0;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 700;

    &:first-child {
      margin: rem(8) 0 rem(24) 0;
    }
  }

  &__side-menu {
    flex: 1;
    overflow-y: auto;
    // padding: 10px;

    display: flex;
    align-items: flex-start;
    // overflow-y: initial;

    // min-height: calc(100vh - 179px);
    // margin: rem(90) 0 rem(16);
    margin: rem(16) 0 rem(16);

    @include respond-max(medium-up) {
      min-height: 0;
      margin: 0;
    }

    @include IE() {
      height: calc(100vh - 179px);
    }

    @include IE-width(medium-up) {
      height: auto;
    }
  }

  .user-account-container {
    padding: $gap * 3 rem(40);
  }

  &__container {
    padding: $gap rem(40);
    box-shadow: inset 2px 4px 8px -2px rgba(0, 0, 0, 0.07);

    @include respond-max(medium-small-up) {
      padding: $gap/2 $gap/2;
    }
  }

  &__content {
    padding: 0;
    margin: rem(24) auto;
    max-width: rem(1060);

    @include respond-max(medium-small-up) {
      margin: 0;
    }

    .info-top {
      margin-bottom: rem(36);
    }

    &--with-cards {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      @include respond-max(large-up) {
        flex-direction: column;
      }
    }

    &.panel {
      padding: $gap;
      margin: rem(32) auto;
      width: 100%;
      max-width: rem(1060);
    }

    + .layout__content {
      margin-top: $gap;
    }
  }

  &__cards {
    width: 100%;
    max-width: rem(720);
    margin-right: rem(32);

    @include respond-max(large-up) {
      order: 2;
      max-width: 100%;
    }
  }

  &__sidebar {
    max-width: rem(308);
    width: 100%;

    @include respond-max(large-up) {
      order: 1;
      margin-bottom: rem(24);
      max-width: 100%;
    }
  }

  &__vertical-spacer {
    width: 100%;

    &--small {
      height: 25px;
    }

    &--medium {
      height: 45px;
    }
  }
}

.notification-icon-box {
  cursor: pointer;

  .counter {
    position: absolute;
    display: flex;
    width: 1.2rem;
    min-width: 1.2rem;
    height: 1.2rem;
    min-height: 1.2rem;
    margin-left: 0.5rem;
    justify-content: center;
    align-items: center;
    background-color: #d8d8d8;
    color: #000;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    border-radius: 50%;
  }

  .label {
    font-size: rem(12);
    color: $colorDark;
  }

  &__active {
    border-bottom: 3px solid $colorBlue;
  }
}

.radio-input {
  appearance: none;
  border: 1px solid $colorBlue;
  border-radius: 50%;
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    background-image: url("../../src/assets/icons/Radio_on.svg");
    border-color: $colorBlue;
  }

  &:disabled {
    border-color: $colorGrey;
    cursor: default;
  }
}

.mark-all-as-seen-div {
  padding: 0;
  max-width: rem(1060);
  margin: rem(24) auto;
}
