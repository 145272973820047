@import "/settings/variables";
@import "/functions";
@import "/mixins/responsive";

.comments-table {
  .table__header {
    display: table;
    table-layout: fixed;
  }

  th {
    position: relative;
    font-weight: 400;
    .sort-dropdown {
      left: 0;
      right: auto;
    }
  }
  tr {
    > table {
      table-layout: fixed;
    }
  }
  td {
    vertical-align: top;
    > span {
      display: initial;
    }
  }

  .showAll {
    min-width: rem(60);
  }
  .id {
    min-width: rem(40);
  }
  .commentId {
    min-width: rem(40);
  }
  .line {
    min-width: rem(104);
  }
  .clause {
    min-width: rem(80);
  }
  .element {
    min-width: rem(210);
  }
  .type {
    min-width: rem(105);
  }
  .author {
    min-width: rem(175);
  }
  .date {
    min-width: rem(108);
    word-wrap: break-word;
  }
  .tags {
    min-width: rem(148);
    text-align: right;
    margin-left: auto;
  }
  .options {
    min-width: rem(34);
  }

  .showAll {
    .icon {
      display: block;
    }
  }

  .collapsed {
    .icon {
      transform: rotate(90deg);
    }
  }

  .expanded {
    .icon {
      transform: rotate(-90deg);
    }
  }
}

.comment__label-box {
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.1rem 0.3rem;
  border-radius: 0.5rem;
  color: #077bfb;
  display: inline-block;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin-right: 0.5rem;
  text-decoration: underline;
}
