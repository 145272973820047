@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";

.form-message-box {
  padding: $gutter $gap/2;
  border-radius: $radius-small;
  text-align: center;

  @include respond-max(medium-up) {
    padding: $gutter rem(40);
  }
  &__success {
    background-color: $colorGreen;
    color: $colorLight;
  }

  &__errors {
    background-color: $colorRed;
    color: $colorLight;
  }
}

.validation-message {
  &__error {
    color: $colorRed;
    display: block;
    padding: rem(10) 0 0 0;
  }
}
