@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";

.button {
  height: rem(40);
  display: inline-block;
  padding: rem(9) rem(24);
  font-weight: 700;
  color: $colorBlue;
  background-color: transparent;
  border: 1px solid $colorBlue;
  border-radius: $radius;
  cursor: pointer;
  appearance: none;
  vertical-align: middle;
  outline: none;

  &:focus:not(.button--link) {
    outline: 0;
    box-shadow: 0 0 rem(9) 0 $colorBlue;
  }

  &--primary {
    color: $colorLight;
    background-color: $colorBlue;
  }

  &--secondary {
    color: $colorBlue;
    border-color: $colorBlue;
    background-color: white;
  }

  &--remove {
    color: $colorRed;
    border-color: $colorRed;
  }

  &--small {
    height: rem(32);
    padding: rem(7) rem(16);
    font-size: rem(12);
    line-height: rem(16);
  }

  &--medium {
    height: rem(32);
    padding: rem(7) rem(16);
    font-size: rem(14);
    line-height: rem(16);
  }

  &--link {
    height: auto;
    padding: 0;
    border-color: transparent;
    font-weight: normal;
    background-color: transparent;
    cursor: pointer;

    * + & {
      margin-left: $gap;
    }

    * + &-no-margin {
      margin: 0;
    }
  }
  &--no-border {
    border: none;
  }
  &--back,
  &--go {
    padding: 0 $gap/4;
    margin-left: $gap/2;
    color: $colorDark;
    font-size: rem(20);
    font-weight: 700;
    border: none;
    border-radius: 0;
  }

  &--full-width {
    width: 100%;
  }

  &--back {
    margin-left: 0;
    margin-right: $gap/4;
  }

  &--disabled {
    pointer-events: none;
    background-color: gray;
    border-color: gray;
  }

  &--link-disabled {
    color: gray;
    pointer-events: none;
  }

  &--awaiting {
    &:before {
      content: " ";
      display: inline-block;
      vertical-align: top;
      width: 16px;
      height: 16px;
      margin: 0px 5px 0px 0px;

      border-radius: 50%;
      border: 2px solid $colorBlue;
      border-color: $colorBlue transparent $colorBlue transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }

  &--vcenter {
    display: flex;
    align-items: center;
  }

  ~ .button {
    margin-left: $gap/3;
  }
}

.buttons {
  @include respond-max(medium-small-up) {
    .button {
      width: 100%;
      margin-left: 0;
      text-align: center;
      + .button {
        margin-top: rem(16);
      }
    }
  }
}

.buttons--medium-up {
  @include respond-max(medium-up) {
    .button {
      width: 100%;
      margin-left: 0;
      text-align: center;
      + .button {
        margin-top: rem(16);
      }
    }
  }
}
