.pointer {
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: contain;
  vertical-align: middle;

  &-goback {
    background-image: url("../assets/icons/go_back.svg");
  }
  &-3dots {
    background-image: url("../assets/icons/3_dots.svg");
  }
  &-arrow-left {
    background-image: url("../assets/icons/arrow_left.svg");
  }
  &-arrow-left-blue {
    background-image: url("../assets/icons/arrow_left_blue.svg");
  }
  &-arrow-right {
    background-image: url("../assets/icons/arrow_right.svg");
  }
  &-arrow-right-blue {
    background-image: url("../assets/icons/arrow_right_blue.svg");
  }
  &-doc {
    background-image: url("../assets/icons/doc.svg");
  }
  &-linkedin {
    background-image: url("../assets/icons/linkedin.svg");
  }
  &-notify-off {
    background-image: url("../assets/icons/Notify_off.svg");
  }
  &-notify-on {
    background-image: url("../assets/icons/Notify_on.svg");
  }
  &-paperclip {
    background-image: url("../assets/icons/paperclip.svg");
  }
  &-pdf {
    background-image: url("../assets/icons/PDF.svg");
  }
  &-pdf-red {
    background-image: url("../assets/icons/PDF-red.svg");
  }
  &-plus {
    background-image: url("../assets/icons/plus_add.svg");
  }
  &-profile-icon {
    background-image: url("../assets/icons/profile_icon.svg");
  }
  &-radio-off {
    background-image: url("../assets/icons/Radio_off.svg");
  }
  &-radio-on {
    background-image: url("../assets/icons/Radio_on.svg");
  }
  &-sidebar-search {
    background-image: url("../assets/icons/sidebar_search.svg");
  }
  &-search {
    background-image: url("../assets/icons/search_icon.svg");
  }
  &-word {
    background-image: url("../assets/icons/word.svg");
  }
  &-ribbon {
    background-image: url("../assets/icons/ribbon.svg");
  }
  &-file {
    background-image: url("../assets/icons/file.svg");
  }
  &-comment {
    background-image: url("../assets/icons/comment.svg");
  }
  &-message {
    background-image: url("../assets/icons/messages.svg");
  }
  &-eye {
    background-image: url("../assets/icons/icons8-eye.svg");
  }
  &-menu-arrow {
    background-image: url("../assets/icons/burger_arrow.svg");
  }
  &-question-mark {
    background-image: url("../assets/icons/question-mark.svg");
  }
  &-check {
    background-image: url("../assets/icons/check.svg");
  }
  &-paperclip {
    background-image: url("../assets/icons/paperclip.svg");
  }
  &-reference {
    background-image: url("../assets/icons/reference.svg");
  }
  &-notification {
    background-image: url("../assets/icons/notification.svg");
  }
  &-settings {
    background-image: url("../assets/icons/settings.svg");
  }

  &-cross-circle {
    background-image: url("../assets/icons/cross_circle.svg");
  }

  &--active {
    filter: invert(25%) sepia(100%) saturate(1947%) hue-rotate(203deg)
      brightness(109%) contrast(97%) !important;
  }

  &--large {
    width: 24px;
    height: 24px;
  }

  &--right-margin {
    margin-right: 5px;
  }
}
