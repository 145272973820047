@import "../../../../../styles/settings/variables";
@import "../../../../../styles/functions";
@import "../../../../../styles/mixins/responsive";

.member {
  @include respond-max(medium-up) {
    width: 100%;
    margin: $gutter 0;
  }

  width: calc(50% - #{$gutter});
  margin: $gutter;

  display: flex;
  flex-direction: column;


  &:nth-child(2n+1) {
    margin-left: 0;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }

  @include respond-max(large-medium-up) {

  }

  &__main-info {
    display: flex;
    align-items: flex-start;
  }

  &__contact {
    margin: 0 0 0 $gutter2;
    width: 100%;
    max-width: calc(100% - 110px);
    word-break: break-word;

    @include IE() {
      word-break: break-all;
    }
  }

  &__name {
    margin-bottom: 0;
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(24);
  }

  &__company {
    display: block;
    margin-bottom: $gutter;
  }

  &__ds-list {
    margin-bottom: $gap/4;
  }

  &__role,
  &__last-activity {
    font-size: rem(12);
    line-height: rem(16);
  }

  &__last-activity {
    margin: 0;
    color: $colorBrightGrey;
  }

  &__activity-state {
    font-weight: 700;
    margin-top: 20px;
  margin-right: 20px;

    cursor: pointer;

    &-active {
      color: #0eb266;
    }
    &-inactive {
      color: #ff5454;
    }
    &-wait {
      color: gray;
    }
  }
  &__linkedin {
    margin-left: auto;
    align-self: flex-start;

    .icon {
      width: rem(24);
      height: rem(24);
    }
  }

  a {
    display: block;
    color: $colorBlue;
  }

  .icon {
    font-size: rem(24);
    line-height: rem(32);
  }

  &__additional-info {
    display: flex;

    &-right {
      margin-left: auto;
    }
  }
}