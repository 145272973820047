@import "../settings/variables";
@import "../functions";
@import "../mixins/responsive";

.textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: rem(160);
  min-height: rem(160);
  padding: rem(11) rem(16);
  background-color: $colorBackground;
  border: rem(1) solid $colorPaleGreyBlue;
  border-radius: $radius-small;
  appearance: none;
  outline: 0;

  &:focus {
    box-shadow: 0 0 rem(4) 0 $colorBlue;
  }

  .is-mobile & {
    font-size: rem(16);
  }
}
